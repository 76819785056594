<template>
  <div>
    <section>
      <template>
        <v-container>
          <v-row justify="center" align="center">
            <v-form class="col s8">
              <v-col cols="12">
                <Subheader
                  icon="mdi-human"
                  title="Bedienungshilfen"
                ></Subheader>
                <div class="mt-7">
                  <v-card>
                    <v-card-text>
                      <v-container>
                        <v-row>
                          <v-col cols="12" sm="12" md="12">
                            <v-checkbox
                              v-model="
                                user.settings.accessability.useColorblindMode
                              "
                              label="Modus für Farbenblinde aktivieren"
                              :disabled="loading"
                              persistent-hint
                              hint="Fügt Muster oder Icons hinzu, damit Elemente nicht nur durch Farbe unterschieden werden können."
                            ></v-checkbox>
                          </v-col>
                        </v-row>
                        <v-card-actions>
                          <v-spacer></v-spacer>
                          <v-btn
                            depressed
                            color="success"
                            :loading="loading"
                            @click="updateSettings"
                          >
                            <v-icon left> mdi-content-save </v-icon>
                            Speichern
                          </v-btn>
                        </v-card-actions>
                      </v-container>
                    </v-card-text>
                  </v-card>
                </div>
              </v-col>
            </v-form>
          </v-row>
        </v-container>
      </template>
    </section>
    <router-view />
  </div>
</template>

<script>
import { db, auth } from "@/firebase";
import Subheader from "@/components/_systemwide/Subheader.vue";
import { USER } from "@/store/modules.js";
import { GET_USER_PROFILE } from "@/store/action-types.js";
export default {
  name: "settings-accessability",
  components: {
    Subheader,
  },
  props: {
    user: Object,
  },
  data() {
    return {
      loading: false,
    };
  },
  methods: {
    updateSettings() {
      this.loading = true;
      var hintRef = db.collection("users").doc(auth.currentUser.uid);
      hintRef
        .update({
          "settings.accessability.useColorblindMode":
            this.user.settings.accessability.useColorblindMode,
        })
        .then(() => {
          console.info("updated");
          this.loading = false;
          this.$store.dispatch(`${USER}${GET_USER_PROFILE}`, {
            uid: auth.currentUser.uid,
            allowRedirect: false,
          });
        })
        .catch(function (error) {
          console.error("Error updating document: ", error);
        });
    },
  },
};
</script>
