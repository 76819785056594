<template>
  <div>
    <v-snackbar v-model="snackbar.model" timeout="3000">{{
      snackbar.text
    }}</v-snackbar>
    <section>
      <template>
        <v-container>
          <v-row justify="center" align="center">
            <v-form class="col s8">
              <v-col cols="12">
                <Subheader
                  icon="mdi-bell"
                  title="Benachrichtigungseinstellungen"
                ></Subheader>
                <div class="mt-7">
                  <v-card :loading="loading">
                    <v-card-text>
                      Lege fest, wie du bei verschiedenen Ereignissen
                      benachrichtigt werden möchtest.
                      <v-data-table
                        :headers="headers"
                        :items="notificationSettings"
                        group-by="group"
                        item-key="name"
                        disable-sort
                        disable-pagination
                        disable-filtering
                        hide-default-footer
                        fixed-header
                        class="mt-2"
                      >
                        <template v-slot:[`group.header`]="{ items }">
                          <th :colspan="headers.length" style="height: 32px">
                            {{ items[0].group.split("_").slice(1).join("_") }}
                          </th>
                        </template>
                        <template v-slot:item="{ item }">
                          <tr class="parent-tr">
                            <td class="inner-td">
                              {{ item.name }}
                              <v-chip
                                v-if="item.comingSoon"
                                x-small
                                class="ml-2"
                                >Demnächst verfügbar</v-chip
                              >
                            </td>
                            <td class="inner-td">
                              <v-simple-checkbox
                                v-model="item.email"
                                :ripple="false"
                                :disabled="item.comingSoon"
                              ></v-simple-checkbox>
                            </td>
                          </tr>
                        </template>
                      </v-data-table>
                    </v-card-text>
                    <v-card-actions
                      ><v-spacer></v-spacer
                      ><v-btn
                        depressed
                        color="success"
                        class="mb-2 mr-2"
                        :loading="loading"
                        @click="updateNotificationSettings()"
                        ><v-icon left>mdi-content-save</v-icon>Speichern</v-btn
                      ></v-card-actions
                    >
                  </v-card>
                </div>
              </v-col>
            </v-form>
          </v-row>
        </v-container>
      </template>
    </section>
    <router-view />
  </div>
</template>

<script>
import Subheader from "@/components/_systemwide/Subheader.vue";
import { USER } from "@/store/modules.js";
import { UPDATE_NOTIFICATION_SETTINGS } from "@/store/action-types.js";
export default {
  name: "settings-notifications",
  components: {
    Subheader,
  },
  props: {
    user: Object,
  },
  data() {
    return {
      snackbar: {
        model: false,
        text: "",
      },
      loading: false,
      headers: [
        { text: "Ereignis", align: "start", sortable: false, value: "name" },
        { text: "E-Mail", value: "email" },
      ],
    };
  },
  computed: {
    notificationSettings() {
      const availableTriggers = [
        {
          name: "Erinnerung an bevorstehende Veranstaltungen",
          comingSoon: true,
          group: "1_Terminplanung",
          id: "scheduling_eventReminder",
        },
        {
          name: " Rückmeldefrist von Veranstaltungen läuft in Kürze ab",
          comingSoon: true,
          group: "1_Terminplanung",
          id: "scheduling_eventDeadlineExpiresSoon",
        },
        // {
        //   name: "Veranstaltungsdetails werden aktualisiert (z.B. Zeit, Ort)",
        //   comingSoon: true,
        //   group: "1_Terminplanung",
        //   id: "scheduling_eventDetailsChanged",
        // },
        {
          name: " Veranstaltungsstatus geändert (z.B. abgesagt)",
          comingSoon: true,
          group: "1_Terminplanung",
          id: "scheduling_eventStatusChanged",
        },
        {
          name: "Personen sagen kurzfristig ihre Teilnahme ab (sofern berechtigt)",
          comingSoon: false,
          group: "1_Terminplanung",
          id: "scheduling_eventCancellationNoticeCreated",
        },
        {
          name: "Einteilung in Veranstaltung",
          group: "1_Terminplanung",
          id: "scheduling_eventResponseIsAccepted",
        },
        {
          name: "Eigene Rückmeldungen durch andere Benutzer geändert",
          group: "1_Terminplanung",
          id: "scheduling_eventResponseChangedByOtherUser",
        },
        // {
        //   name: "Eigene Rückmeldungen wird durch andere Benutzer geändert",
        //   group: "3_Dienstverwaltung",
        //   id: "scheduling_eventResponseChangedByOtherUser",
        // },
        {
          name: "Eigene Abmeldung läuft in Kürze ab",
          comingSoon: true,
          group: "4_Verfügbarkeit",
          id: "availability_ownLeaveExpiresSoon",
        },
        {
          name: "Eigene Abmeldung durch andere Benutzer geändert",
          comingSoon: true,
          group: "4_Verfügbarkeit",
          id: "availability_ownLeaveChangedByOtherUser",
        },
        {
          name: "Eigene Abmeldung durch andere Benutzer geändert",
          comingSoon: true,
          group: "4_Verfügbarkeit",
          id: "availability_ownLeaveChangedByOtherUser",
        },
        {
          name: "Neue Abwesenheiten in Organisation erstellt",
          comingSoon: true,
          group: "4_Verfügbarkeit",
          id: "availability_newLeavesCreated",
        },
        {
          name: "Eigene Abmeldung läuft in Kürze ab",
          comingSoon: true,
          group: "4_Verfügbarkeit",
          id: "availability_ownLeaveExpiresSoon",
        },
        {
          name: "Erinnerung an bevorstehende Abfragen",
          comingSoon: true,
          group: "4_Verfügbarkeit",
          id: "availability_requestReminder",
        },
        {
          name: "Rückmeldefrist von Veranstaltungen läuft in Kürze ab",
          comingSoon: true,
          group: "4_Verfügbarkeit",
          id: "availability_requestDeadlineExpiresSoon",
        },
        // {
        //   name: "Abfragedetails werden aktualisiert (z.B. Zeit, Ort)",
        //   comingSoon: true,
        //   group: "4_Verfügbarkeit",
        //   id: "availability_requestDetailsChanged",
        // },
        // {
        //   name: "Abfragedetails werden aktualisiert (z.B. Zeit, Ort)",
        //   comingSoon: true,
        //   group: "4_Verfügbarkeit",
        //   id: "availability_requestDetailsChanged",
        // },
      ];
      const activeNotificationTriggers =
        this.user.settings.notifications.email.activeTriggers;
      return availableTriggers.map((trigger) => {
        return {
          ...trigger,
          email: activeNotificationTriggers.includes(trigger.id),
        };
      });
    },
    activeTriggersEmail() {
      return this.notificationSettings
        .filter((trigger) => trigger.email)
        .map((trigger) => trigger.id);
    },
  },
  methods: {
    updateNotificationSettings() {
      this.loading = true;
      this.$store
        .dispatch(`${USER}${UPDATE_NOTIFICATION_SETTINGS}`, {
          activeTriggersEmail: this.activeTriggersEmail,
        })
        .then(() => {
          this.loading = false;
          this.snackbar.model = true;
          this.snackbar.text =
            "Benachrichtigungseinstellungen erfolgreich aktualisiert.";
        })
        .catch((error) => {
          console.log(error);
          this.loading = false;
          this.snackbar.model = true;
          this.snackbar.text = error.message;
        });
    },
  },
};
</script>
